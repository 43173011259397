article {
  max-width: 100%;
  line-height: 1.9;
  letter-spacing: -0.2px;
}
article > * + p {
  color: var(--fg);
  margin: var(--gap-15) 0;
}

article img {
  max-width: 100%;
  width: auto;
  margin: auto;
  display: block;
  border-radius: var(--border-radius);
}

article h2 {
  margin-top: 4rem;
}

article strong {
  font-weight: 600;
}

article h3 {
  margin-top: var(--gap-triple);
}

article hr {
  border: none;
  border-top: var(--border);
}

article em {
  display: block;
  text-align: center;
  font-size: var(--font-size-sm);
  color: var(--fg-light);
}

article img {
  @apply p-3 rounded md:p-5 bg-layer-2 dark:bg-layer-3/70;
}

/* Blockquote*/
article blockquote {
  margin: 0;
  padding: var(--gap) var(--gap-15);
  border-left: 4px solid var(--highlight);
  background-color: var(--overlay);
}

article blockquote * {
  color: var(--fg-light);
}

article blockquote p {
  font-style: italic;
  font-size: var(--font-size-sm);
  line-height: 1.25;
}

article blockquote p code {
  font-style: normal;
}

/* Code Snippets */
article pre {
  overflow-x: auto;
  /* background: var(--layer-3); */
  border-radius: var(--border-radius);
  line-height: 1.8;
  padding: var(--gap) var(--gap-15);
  font-size: var(--font-size-sm);

  @apply bg-layer-2 dark:bg-layer-3/70 text-sm;
}

article *:not(pre) > code {
  font-size: var(--font-size-sm);
  padding: 0 8px;
  background: none;
}

article *:not(pre) > code::before,
article *:not(pre) > code::after {
  content: "\`";
  color: var(--fg);
  user-select: none;
}

/* List */
article ul {
  padding: 0 var(--gap-75);
  list-style-position: inside;
  list-style-type: circle;
}

article ol {
  padding: var(--gap-half);
  list-style-position: outside;
}

article li {
  margin-top: var(--gap);
}

article ul li {
  list-style-type: disc;
  color: var(--fg);
  font-size: var(--font-size-sm);
  line-height: 1.25;
}

article ul li strong {
  color: var(--fg);
}

article a {
  position: relative;
  text-decoration: none;
}

article a:hover {
  text-decoration: none;
}

article a::after {
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -2px;
  left: 0;
  transition: all 0.15s;
  content: " ";
  background-color: var(--fg);
  opacity: 0.35;
}

article a::before {
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -2px;
  transition: all 0.15s;
  content: " ";
  background-color: var(--fg);
}

article a:hover::before {
  width: 100%;
}
